import React from 'react';

import JLL from './assets/JLL.jpg';
import lendlease from './assets/lendlease.jpg';
import nuveen from './assets/nuveen.png';

import { Image, ExternalLink } from 'components';

import styles from './styles.module.scss';

const Sponsors = () => (
  <div className={styles.sponsors}>
    <div className={styles.headline}>Headline Sponsors</div>
    <div className={styles.imageContainer}>
      <ExternalLink href="https://www.jll.co.uk/">
        <Image className={styles.image} src={JLL} />
      </ExternalLink>
      <ExternalLink href="https://www.lendlease.com/uk/">
        <Image className={styles.image} src={lendlease} />
      </ExternalLink>
      <ExternalLink href="https://www.nuveen.com/global/strategies/real-estate">
        <Image className={styles.image} src={nuveen} />
      </ExternalLink>
    </div>
  </div>
);

export default Sponsors;
